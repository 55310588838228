// extracted by mini-css-extract-plugin
export var blog = "Home-module--blog--e10cf";
export var btn = "Home-module--btn--644a1";
export var btnWrapper = "Home-module--btn-wrapper--2dd0f";
export var constructions = "Home-module--constructions--a1489";
export var controls = "Home-module--controls--31e55";
export var disabled = "Home-module--disabled--64cad";
export var grid = "Home-module--grid--f0bff";
export var head = "Home-module--head--e1989";
export var hero = "Home-module--hero--cb798";
export var highlight = "Home-module--highlight--0128d";
export var highlights = "Home-module--highlights--67b10";
export var history = "Home-module--history--32921";
export var large = "Home-module--large--c1e8c";
export var legend = "Home-module--legend--f4759";
export var legendAppear1 = "Home-module--legend-appear1--02501";
export var legendAppear11 = "Home-module--legend-appear1-1--e61ab";
export var legendAppear2 = "Home-module--legend-appear2--73dbc";
export var link = "Home-module--link--31c32";
export var numbers = "Home-module--numbers--bcf37";
export var otherSegments = "Home-module--other-segments--6128e";
export var segments = "Home-module--segments--484b5";
export var slide = "Home-module--slide--e41c7";
export var slider = "Home-module--slider--b2a2f";
export var small = "Home-module--small--9f161";
export var technology = "Home-module--technology--8bcff";
export var textAppear = "Home-module--text-appear--9d2e1";
export var thumbnail = "Home-module--thumbnail--3fdca";
export var video = "Home-module--video--9837a";
export var visible = "Home-module--visible--ec397";