import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ApiHook, Button, Footer, Grid, Head, Link, Slider, Translate } from '@components'
import { requestBlogData } from '@api'
import locale from '@data/locales/home'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/Home.module.styl'
import video from '@assets/videos/video.mp4'

const SegmentCard = ({ segment }) => {
  const [style, setStyle] = useState<React.CSSProperties>()

  return Translate(segment, 'slug', to => <Link prefix="segment" to={to} className={st.highlight} style={style}
      onMouseEnter={() => setStyle({ zIndex: 5 })}
      onMouseLeave={() => {
        setStyle({ zIndex: 1 })
        setTimeout(() => setStyle(undefined), 200)
      }}
    >
      <div style={{ backgroundImage: `url(${segment.cover})` }}>
        <div style={{ backgroundImage: `url(${segment.mobile})` }}></div>
      </div>
      <h4><span>{Translate(segment, 'name')}</span></h4>
    </Link>
  )
} 

export default ({ location: { pathname } }) => {
  const query = useStaticQuery(graphql`
    query {
      allSegments {
        nodes {
          cover
          mobile
          name_pt
          name_en
          name_es
          slug_pt
          slug_en
          slug_es
        }
      }
      allShowcases {
        nodes {
          image
          mobile
          description_one_pt
          description_one_en
          description_one_es
          description_two_pt
          description_two_en
          description_two_es
          description_three_pt
          description_three_en
          description_three_es
          external
          url_pt
          url_en
          url_es
        }
      }
      allConstructionReleases {
        nodes {
          title_pt
          title_en
          title_es
          slug_pt
          slug_en
          slug_es
          segments {
            name_pt
            name_en
            name_es
          }
          cover
        }
      }
      allNumbers {
        nodes {
          icon
          number_pt
          number_en
          number_es
          text_pt
          text_en
          text_es
        }
      }
      allBlogPostings(limit: 10) {
        nodes {
          title
          excerpt
          slug
          image
        }
      }
    }
  `)
  const showcases = ApiHook(query.allShowcases.nodes, 'showcases')
  const numbers = ApiHook(query.allNumbers.nodes, 'numbers')
  const segments = ApiHook(query.allSegments.nodes, 'segments?max=9999')
  const constructions = ApiHook(query.allConstructionReleases.nodes, 'constructions?feature=true')
  const [heroSlide, setHeroSlide] = useState(0)
  const [consSlide, setConsSlide] = useState(0)
  const [consLastSlide, setConsLastSlide] = useState(2)
  const [slidesToShow, setSlidesToShow] = useState(2)
  const [blogSlide, setBlogSlide] = useState(0)
  const videoRef = useRef<HTMLVideoElement>()

  const [posts, setPosts] = useState(query.allBlogPostings.nodes)
  useEffect(() => {
    requestBlogData('get', `posts?_embed&per_page=10&page=1`)
      .then(res =>
        setPosts(res.data.map(post => {
          const { title: { rendered: title }, excerpt: { rendered: excerpt }, _embedded, slug } = post
          return {
            title,
            excerpt,
            slug,
            image: _embedded['wp:featuredmedia'][0] && _embedded['wp:featuredmedia'][0].source_url
          }
        }))
      )
  }, [])

  useEffect(() => {
    const onWindowResize = () => {
      if (window.matchMedia('(min-width: 1680px)').matches) setConsLastSlide(3)
      else if (window.matchMedia('(min-width: 950px)').matches) setConsLastSlide(2)
      else if (window.matchMedia('(min-width: 700px)').matches) setConsLastSlide(3)
      else if (window.matchMedia('(min-width: 470px)').matches) setConsLastSlide(2)
      else setConsLastSlide(1)
      if (window.matchMedia('(min-width: 950px)').matches) setSlidesToShow(3)
      else setSlidesToShow(2)
    }
    onWindowResize()
    window.addEventListener('resize', onWindowResize)
    const playVideo = () => {
      const video = videoRef.current
      if (!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2))
        video.play()
      document.body.removeEventListener('click', playVideo)
      document.body.removeEventListener('touchstart', playVideo)
    }
    document.body.addEventListener('click', playVideo)
    document.body.addEventListener('touchstart', playVideo)
    return () => {
      window.removeEventListener('resize', onWindowResize)
      document.body.removeEventListener('click', playVideo)
      document.body.removeEventListener('touchstart', playVideo)
    }
  }, [])

  return <>
    {Translate(locale, 'head_title', title => <Head title={title} path={pathname} />)}

    <section className={st.hero} id="home-header">
      <div>
        <Grid className={st.grid}>
          {showcases.map((slide, key) => <div key={key} className={cn(st.legend, heroSlide === key || (heroSlide >= showcases.length && key === 0) || (heroSlide < 0 && key === showcases.length-1) ? st.visible : undefined)}>
            <span className="icon-big-arrow"></span>
            {Translate(slide, 'description_one', description => <span dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br/>') }}></span>)}
          </div>)}
          <div className={st.controls}>
            {Boolean(showcases.length - 1) && <>
              <button className="icon-slider-arrow" onClick={() => setHeroSlide(heroSlide - 1)}></button>
              {heroSlide >= showcases.length ? 1 : heroSlide < 0 ? showcases.length : heroSlide + 1}
              <span></span>
              {showcases.length}
              <button className="icon-slider-arrow" onClick={() => setHeroSlide(heroSlide + 1)}></button>
            </>}
          </div>
          <h1>{Translate(locale, 'title')}</h1>
          <Slider currentSlide={heroSlide} goToSlide={setHeroSlide} className={st.slider} dots>
            {showcases.map((slide, key) => <div key={key} className={st.slide}>
              <p>{Translate(slide, 'description_three')}</p>
              {!slide.url_pt ?
                <div style={{ backgroundImage: `url(${slide.image})` }}><div style={{ backgroundImage: `url(${slide.mobile})` }}></div></div> :
                Translate(slide, 'url', href => {
                  if (slide.external) return <a href={href} target="_blank" style={{ backgroundImage: `url(${slide.image})` }}>
                    <div style={{ backgroundImage: `url(${slide.mobile})` }}></div>
                  </a>
                  return <Link noFix to={href} style={{ backgroundImage: `url(${slide.image})` }}>
                    <div style={{ backgroundImage: `url(${slide.mobile})` }}></div>
                  </Link>
                })
              }
            </div>)}
          </Slider>
        </Grid>
      </div>
      <div>
        <Grid className={st.grid}>
          <div className={st.legend}>
            {showcases.map((slide, key) => <span key={key} className={cn(heroSlide === key || (heroSlide >= showcases.length && key === 0) || (heroSlide < 0 && key === showcases.length-1) ? st.visible : undefined)}>
              {Translate(slide, 'description_two')}
            </span>)}
          </div>
          {showcases.map((slide, key) => <div key={key} className={cn(heroSlide === key || (heroSlide >= showcases.length && key === 0) || (heroSlide < 0 && key === showcases.length-1) ? st.visible : undefined)}>
            <p>{Translate(slide, 'description_three')}</p>
          </div>)}
          {Translate(locale, 'request_quote_url', url => <Button to={url} className={st.btn}>{Translate(locale, 'request_quote') as unknown as string}</Button>)}
        </Grid>
      </div>
    </section>

    <section className={st.numbers} id="home-numbers">
      <Grid className={st.grid}><div>
        {numbers.map((number, key) => <div key={key}>
          <img src={number.icon} />
          <h4>{Translate(number, 'number')}</h4>
          {Translate(number, 'text')}
        </div>)}
      </div></Grid>
    </section>

    <section className={st.segments}>
      <div className={st.highlights} id="home-highlights">
        <Grid>
          <div>
            <h3>{Translate(locale, 'our_segments')}</h3>
            <p>{Translate(locale, 'our_segments_description')}</p>
          </div>
          <SegmentCard segment={segments[0]} />
          <SegmentCard segment={segments[1]} />
          <div>
            <h3>{Translate(locale, 'our_segments')}</h3>
            <p>{Translate(locale, 'our_segments_description')}</p>
          </div>
          <SegmentCard segment={segments[2]} />
          <SegmentCard segment={segments[3]} />
          <SegmentCard segment={segments[4]} />
        </Grid>
      </div>
      <div className={st.otherSegments} id="home-segments">
        <Grid className={cn(st.grid, 'show-list')}>
          {segments.slice(5).map((segment, key) => <div key={key}>
            {Translate(segment, 'slug', to => <Link prefix="segment" to={to}>{Translate(segment, 'name')}</Link>)}
          </div>)}
        </Grid>
      </div>
    </section>

    <section className={st.history} id="home-history">
      <Grid className={st.grid}>
        <div>
          <h3>{Translate(locale, 'history')}</h3>
          <p>{Translate(locale, 'history_description')}</p>
          {Translate(locale, 'history_url', url => <Button to={url} className={st.btn}>{Translate(locale, 'history_link') as unknown as string}</Button>)}
        </div>
        <div>
          <div className={st.btnWrapper}>
            {Translate(locale, 'history_url', url => <Button to={url} className={st.btn}>{Translate(locale, 'history_link') as unknown as string}</Button>)}
          </div>
        </div>
      </Grid>
    </section>

    <section className={st.technology} id="home-technology">
      <div className={st.video}>
        <video autoPlay playsInline muted loop ref={videoRef}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <Grid wide className={st.grid}>
        <div>
          <h3>{Translate(locale, 'technology')}</h3>
          <div>
            <p>{Translate(locale, 'technology_description')}</p>
            {Translate(locale, 'technology_url', url => <Button to={url}>{Translate(locale, 'technology_link') as unknown as string}</Button>)}
          </div>
        </div>
      </Grid>
    </section>

    <section className={st.constructions} id="home-constructions">
      <div>
        <Grid>
          <div className={st.head}>
            <h3>{Translate(locale, 'constructions_highlights')}</h3>
            <p>{Translate(locale, 'constructions_highlights_description')}</p>
          </div>
          <div className={st.controls}>
            <button className={cn('icon-arrow', consSlide === 0 && st.disabled)} onClick={() => setConsSlide(consSlide - 1)}></button>
            <button className={cn('icon-arrow', consSlide >= constructions.length - consLastSlide && st.disabled)} onClick={() => setConsSlide(consSlide + 1)}></button>
          </div>
          <Slider slidesToScroll={1} currentSlide={consSlide} goToSlide={setConsSlide} lastSlide={constructions.length - consLastSlide} className={st.slider} finite>
            {constructions.map((construction, key) => <Fragment key={key}>{Translate(construction, 'slug', slug => <Link prefix="construction" to={slug} className={st.slide}>
              <h5>{Translate(construction.segments[0], 'name')}</h5>
              <h4>{Translate(construction, 'title')}</h4>
              <div className={st.thumbnail} style={{ backgroundImage: `url(${construction.cover})` }}></div>
            </Link>)}</Fragment>)}
          </Slider>
        </Grid>
      </div>
    </section>

    <section className={st.blog} id="home-blog">
      <Grid className={st.grid}><div>
        <h3>Blog</h3>
        <div className={st.controls}>
          <button className="icon-arrow" onClick={() => setBlogSlide(blogSlide - slidesToShow)}></button>
          <button className="icon-arrow" onClick={() => setBlogSlide(blogSlide + slidesToShow)}></button>
        </div>
        <Slider slidesToScroll={slidesToShow} currentSlide={blogSlide} goToSlide={setBlogSlide} className={st.slider}>
          {posts.map(({ title, excerpt, slug, image }, key) => <div key={key} className={st.slide}>
            <div className={st.thumbnail} style={{ backgroundImage: `url(${image})` }}></div>
            <h4>{title}</h4>
            <p>{excerpt ? excerpt.replace(/<[^>]*>?/gm, '').slice(0, 100) + '...' : ''}</p>
            <Link className={st.link} to={slug} prefix="blog">Ver mais</Link>
          </div>)}
        </Slider>
        <ul>
          {posts.slice(0, 3).map(({ title, excerpt, slug, image }, key) => <li key={key} className={st.slide}>
            <div className={st.thumbnail} style={{ backgroundImage: `url(${image})` }}></div>
            <h4>{title}</h4>
            <p>{excerpt ? excerpt.replace(/<[^>]*>?/gm, '').slice(0, 100) + '...' : ''}</p>
            <Link className={st.link} to={slug} prefix="blog">Ver mais</Link>
          </li>)}
        </ul>
      </div></Grid>
    </section>

    <Footer />
  </>
}