import React from 'react'

export default {
  head_title_pt: 'Rotesma - Indústria de pré-fabricados de concreto',
  head_title_en: 'Rotesma - Indústria de pré-fabricados de concreto',
  head_title_es: 'Rotesma - Prefabricados en hormigón',
  title_pt: <><span>Indústria de</span><span><span>Pré-Fabricado em Concreto</span></span></>,
  title_en: <><span>Indústria de</span><span><span>Pré-Fabricado em Concreto</span></span></>,
  title_es: <><span>Industria de</span><span><span>Prefabricados de hormigón</span></span></>,
  request_quote_pt: 'Solicite um orçamento',
  request_quote_en: 'Solicite um orçamento',
  request_quote_es: 'Solicitar presupuesto',
  request_quote_url_pt: 'orcamento',
  request_quote_url_en: 'request-a-quote',
  request_quote_url_es: 'presupuesto',
  our_segments_pt: 'Nossos segmentos',
  our_segments_en: 'Nossos segmentos',
  our_segments_es: 'Nuestras áreas',
  our_segments_description_pt: 'Estruturas cuidadosamente planejadas para durar, obras que geram impacto cultural, social e econômico, projetos que preveem o crescimento.',
  our_segments_description_en: 'Estruturas cuidadosamente planejadas para durar, obras que geram impacto cultural, social e econômico, projetos que preveem o crescimento.',
  our_segments_description_es: 'Estructuras cuidadosamente planificadas para perdurar, obras que generan impacto cultural, social y económico, proyectos que predicen crecimiento.',
  history_pt: 'Da matéria prima a uma estrutura cuidadosamente concluída.',
  history_en: 'Da matéria prima a uma estrutura cuidadosamente concluída.',
  history_es: 'De la materia prima a una estructura cuidadosamente terminada.',
  history_description_pt: 'A Rotesma carrega uma história de excelência, que desde 1977 oferece o potencial máximo de cada projeto.',
  history_description_en: 'A Rotesma carrega uma história de excelência, que desde 1977 oferece o potencial máximo de cada projeto.',
  history_description_es: 'Rotesma lleva una trayectoria de excelencia, que desde 1977 ofrece el máximo potencial de cada proyecto.',
  history_link_pt: 'Conheça nossa história',
  history_link_en: 'Conheça nossa história',
  history_link_es: 'Descubre nuestra historia',
  history_url_pt: 'sobre',
  history_url_en: 'about',
  history_url_es: 'sobre',
  technology_pt: 'Tecnologia integrada em todos os processos',
  technology_en: 'Tecnologia integrada em todos os processos',
  technology_es: 'Tecnología integrada en todos los procesos',
  technology_description_pt: <>Tecnologia aplicada em cada estrutura de pré-fabricados.<br/>Todo processo é 100% conectado, permitindo agilidade de projeto, produção e logística.</>,
  technology_description_en: <>Tecnologia aplicada em cada estrutura de pré-fabricados.<br/>Todo processo é 100% conectado, permitindo agilidade de projeto, produção e logística.</>,
  technology_description_es: <>Tecnología aplicada en cada estructura prefabricada.<br/>Cada proceso está 100% conectado, lo que permite agilidad en el diseño, la producción y la logística.</>,
  technology_link_pt: 'Conheça nossos diferenciais',
  technology_link_en: 'Conheça nossos diferenciais',
  technology_link_es: 'Descubra nuestros diferenciales',
  technology_url_pt: 'tecnologia',
  technology_url_en: 'technology',
  technology_url_es: 'tecnologia',
  constructions_highlights_pt: 'Obras em destaque',
  constructions_highlights_en: 'Obras em destaque',
  constructions_highlights_es: 'Obras destacadas',
  constructions_highlights_description_pt: 'Rotesma é a entrega de uma obra rápida, limpa e segura, criada com tecnologia e personalização.',
  constructions_highlights_description_en: 'Rotesma é a entrega de uma obra rápida, limpa e segura, criada com tecnologia e personalização.',
  constructions_highlights_description_es: 'Rotesma es entrega de obra rápida, limpia y segura, creada con tecnología y personalización.',
}
